body {
  margin: 0;
  padding: 0;
  /* display: grid;
  align-items: center;
  grid-template-columns: 1fr;
    grid-template-rows: 1fr; */
  /* display: flex;
  justify-content: center; */
  /* max-width: 1440px; */

  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* overflow: hidden; */

  /* border: 1px solid black; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

#root{
  /* border: 2px solid green; */
  width: 100%;
  /* max-width: 1280px; */
  /* padding-left: 180px;
  padding-right: 180px; */
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

:root {
  --primary: #DE5843;
  --secondary: #1E1E1E;
  --white: #FFFFFF;
  --border: #B4B4B4;
  --red: #F00;
}